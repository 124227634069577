h1,h2,h3,h4,h5,p{
  color:#666;
}
hr{
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}

.center{
  text-align: center;
  margin: auto;
  display: block;
  margin-bottom: 2em;
}

.padd{
  padding: 0.5em;
}

.msg-error{
  color:darkred;
  font-style: bold;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}


.MuiStepLabel-label {
  font-size: .75rem!important;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  #AU_Helper { width: 315px }  /* show it on small screens */
}

@media screen and (min-width: 501px) and (max-width: 1024px) {
  #AU_Helper { width: 545px }   /* hide it elsewhere */
}