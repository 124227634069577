$default-font-size: 16px;

$color-basic--brightest: #ffffff;
$color-basic--brighter: #f3f3f3;
$color-basic--bright: #dadada;
$color-basic--normal: null;
$color-basic--dark: #9c9c9c;
$color-basic--darker: #585858;
$color-basic--darkest: #131313;

$font-weight--light: 200;
$font-weight--semilight: 300;
$font-weight--normal: 500;
$font-weight--semibold: 600;
$font-weight--bold: 700;
$font-weight--black: 800;

$font-size--xs: 0.5rem;
$font-size--s: 0.85rem;
$font-size--m: 1rem;
$font-size--l: 1.25rem;
$font-size--xl: 1.5rem;

$media--xxs: 60px;
$media--xs: 60px;
$media--s: 60px;
$media--m: 1024px;
$media--l: 1280px;
$media--xl: 1440px;

$z--xs: 1;
$z--s: 10;
$z--m: 20;
$z--l: 30;
$z--xl: 50;
$z--xxl: 100;
