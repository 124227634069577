/**
 * Define global styles for the application,
 * usually html and body content.
 */

@import '../variables';

html,
body {
  color: $color-basic--darkest;
  font-family: 'Roboto', sans-serif;
  // font-size: calc($default-font-size * 0.65);
  height: 100%;
  width: 100%;
  margin: 0;
  background-attachment: scroll;
  background-size: cover;

  @media screen and (min-width: $media--s) {
    font-size: calc(#{$default-font-size} * 0.8);
  }

  @media screen and (min-width: $media--m) {
    font-size: $default-font-size;
  }
}

html {
  background: radial-gradient(38.63% 66.4% at 0% 100%, rgba(81, 116, 149, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(54.32% 55.46% at 94.44% 93.59%, rgba(241, 118, 51, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  height: 100%;
}

a,
a:active,
a:hover,
a:visited {
  text-decoration: none;
}
