/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.MuiDropzonePreviewList-removeButton {
  top: 0 !important;
}
