/*** Not Inject ***/
@import '../../../styles/variables';

/* Import Vendor */
@import '../../../styles/vendor/bootstrap';

/* Import Base */
@import '../../../styles/base/mixins/mixin';
@import '../../../styles/base/global';
@import '../../../styles/base/fonts';
@import '../../../styles/base/states';
@import '../../../styles/base/utilities';

/* Import Components */
@import '../../../styles/components/logo.component';

.category {
  font-size: 14px;
  color: darkgray;
  padding-right: 5px;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.photo {
  width: 100px;
  height: 100px;
  // object-fit: cover;
  margin: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
